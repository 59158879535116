import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[(false)?_c(VRow,{staticClass:"theme_rounded mb-4",staticStyle:{"background":"#fff"}},[_c(VCol,{attrs:{"cols":"auto","md":"2"}},[_c(VRow,{staticClass:"flex_align_center",staticStyle:{"margin-top":"3px","color":"#000"},attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"3"}},[_vm._v("Type")]),_c(VCol,{attrs:{"cols":"9"}},[_c(VCombobox,{attrs:{"dense":"","outlined":"","hide-details":"","items":['All', 'Checkin', 'Checkout']},model:{value:(_vm.filterData.searchType),callback:function ($$v) {_vm.$set(_vm.filterData, "searchType", $$v)},expression:"filterData.searchType"}})],1)],1)],1),_c(VCol,{attrs:{"cols":"auto","md":"3"}},[_c(VRow,{staticClass:"flex_align_center",staticStyle:{"margin-top":"3px","color":"#000"},attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"4"}},[_vm._v("Employee")]),_c(VCol,{attrs:{"cols":"8"}},[_c(VCombobox,{attrs:{"dense":"","outlined":"","hide-details":"","items":_vm.$store.getters['employee/employees'],"item-text":"display_text","item-value":"id"},on:{"focus":function($event){return _vm.$store.dispatch('employee/getEmployees')}},model:{value:(_vm.employee),callback:function ($$v) {_vm.employee=$$v},expression:"employee"}})],1)],1)],1),_c(VCol,{attrs:{"cols":"auto","md":"4"}},[_c(VRow,{staticClass:"flex_align_center",staticStyle:{"margin-top":"3px","color":"#000"},attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"4"}},[_vm._v("Date")]),_c(VCol,{attrs:{"cols":"8"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":0,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":"","label":"From Date","autocomplete":"off"},model:{value:(_vm.filterData.date_from),callback:function ($$v) {_vm.$set(_vm.filterData, "date_from", $$v)},expression:"filterData.date_from"}},'v-text-field',attrs,false),on),[_c(VIcon,{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-calendar-month")])],1)]}}],null,false,263714623),model:{value:(_vm.monthMenue),callback:function ($$v) {_vm.monthMenue=$$v},expression:"monthMenue"}},[_c(VDatePicker,{on:{"input":function($event){_vm.monthMenue = false}},model:{value:(_vm.filterData.date_from),callback:function ($$v) {_vm.$set(_vm.filterData, "date_from", $$v)},expression:"filterData.date_from"}})],1),_c(VMenu,{staticStyle:{"margin-top":"10px","display":"block"},attrs:{"close-on-content-click":false,"nudge-right":0,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":"","label":"To Date","autocomplete":"off"},model:{value:(_vm.filterData.date_to),callback:function ($$v) {_vm.$set(_vm.filterData, "date_to", $$v)},expression:"filterData.date_to"}},'v-text-field',attrs,false),on),[_c(VIcon,{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-calendar-month")])],1)]}}],null,false,2158311295),model:{value:(_vm.monthMenueTo),callback:function ($$v) {_vm.monthMenueTo=$$v},expression:"monthMenueTo"}},[_c(VDatePicker,{on:{"input":function($event){_vm.monthMenueTo = false}},model:{value:(_vm.filterData.date_to),callback:function ($$v) {_vm.$set(_vm.filterData, "date_to", $$v)},expression:"filterData.date_to"}})],1)],1)],1)],1),_c(VCol,[_c(VBtn,{attrs:{"color":"green","dark":""},on:{"click":_vm.handleSearch}},[_vm._v("Search")])],1)],1):_vm._e(),_c(VRow,[_c(VCol,{staticClass:"pb-0 shadow1 white theme_rounded",attrs:{"cols":"12"}},[_c(VDataTable,{staticClass:"custom-data-table",attrs:{"headers":_vm.departmentHeaders,"search":_vm.searchDepartment,"items":_vm.$store.getters['attendance/attendances'],"loading":_vm.$store.getters['attendance/loadingAttendance'],"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"dense":"","color":"white","elevation":0,"height":"50"}},[_c(VToolbarTitle,{staticClass:"subtitle-1"},[_vm._v("Attendance List")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VForm,{staticClass:"custom-form"},[_c(VTextField,{staticStyle:{"width":"300px"},attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search department","append-icon":"mdi-magnify"},model:{value:(_vm.searchDepartment),callback:function ($$v) {_vm.searchDepartment=$$v},expression:"searchDepartment"}})],1),_c(VSpacer)],1)]},proxy:true},(_vm.userType == 'super_admin' || _vm.userType == 'admin')?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editDepartment(item)}}},on),[_vm._v("mdi-circle-edit-outline")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":"","color":"error"},on:{"click":function($event){_vm.departmentId = item.id;_vm.$refs.confirmDialog.dialog = true}}},on),[_vm._v("mdi-delete-circle-outline")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}:null],null,true)}),_c('confirm-dialog',{ref:"confirmDialog",on:{"confirm":_vm.deleteDepartment}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }